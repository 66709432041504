import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Stack,
  Typography,
} from '@mui/material';

export const BlockArena = () => {
  return (
    <Container id="block-2" sx={{ py: { xs: 8, sm: 8 } }}>
      <Typography
        variant="h4"
        textAlign={'center'}
        mb={4}
        textTransform={'uppercase'}
        letterSpacing={2}
        sx={{
          textShadow: `rgb(255, 186, 24) 0px 0px 1px, rgb(255, 186, 24) 10px 0px 20px, rgb(255, 186, 24) 0px 0px 16px`,
        }}
      >
        PAR PUB NIGHTS
      </Typography>

      <Grid container spacing={{ xs: 3, sm: 6 }} mb={4}>
        {/* LEFT */}
        <Grid item xs={12} md={6}>
          <Box
            component={'img'}
            src={
              'https://res.cloudinary.com/dqlqwdqs9/image/upload/v1717363166/arena_tiers_bhcz5n.png'
            }
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        </Grid>
        {/* RIGHT */}
        <Grid item xs={12} md={6}>
          <Stack spacing={2}>
            <Typography
              variant="h6"
              fontWeight={600}
              letterSpacing={2}
              textAlign={{ xs: 'center', md: 'left', lg: 'left' }}
              color={'primary'}
            >
              RANKED PLAY
            </Typography>
            <Typography>
              Qualify in one of six Tiers on the Ladder. Play a minimum of 5 Pub
              Night games to get calibrated. We've implemented the TrueSkill
              rating system, so you can be sure that everyone is rated
              accurately.
            </Typography>
          </Stack>
        </Grid>
      </Grid>

      <Grid container spacing={{ xs: 3, sm: 6 }}>
        {/* LEFT */}
        <Grid item xs={12} md={6}>
          <Stack spacing={2}>
            <Typography
              variant="h6"
              fontWeight={600}
              letterSpacing={2}
              textAlign={{ xs: 'center', md: 'left', lg: 'left' }}
              color={'primary'}
            >
              EARN ACHIEVEMENTS
            </Typography>
            <Typography>
              Show off your skills with unique achievements to earn.
              Achievements are earned by completing specific tasks in-game.
            </Typography>
            <Typography
              variant="h6"
              fontWeight={600}
              letterSpacing={2}
              textAlign={{ xs: 'center', md: 'left', lg: 'left' }}
              color={'primary'}
            >
              TIER POINTS
            </Typography>
            <Typography>
              Tier Points is a points system that combines your TrueSkill
              internal rating with points earned from Achievements. Every
              Achievement has a Rarity, the more rare, the more points you earn.
            </Typography>
          </Stack>
        </Grid>
        {/* RIGHT */}
        <Grid item xs={12} md={6}>
          <Box
            component={'img'}
            src={
              'https://res.cloudinary.com/dqlqwdqs9/image/upload/v1717366079/arena_achievements_idorgf.png'
            }
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
