import { ArrowForward } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Link,
  Stack,
  Typography,
} from '@mui/material';

export const BlockMain = () => {
  return (
    <Container id="block-main" sx={{ py: 8, mt: { xs: 1, sm: 7 } }}>
      <Grid container spacing={{ xs: 3, sm: 6 }}>
        {/* LEFT */}
        <Grid item xs={12} md={6} lg={5}>
          <Stack
            height={'100%'}
            alignItems={{ sm: 'center', md: 'flex-start' }}
            justifyContent={'center'}
            spacing={2}
          >
            <Typography color="primary" variant="body1" letterSpacing={2}>
              FREE TO PLAY | FAST-ACTION | MULTIPLAYER
            </Typography>
            <Typography
              variant="h1"
              fontWeight={800}
              fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
            >
              A REAL-TIME TRIVIA GAME
            </Typography>

            <Button
              color="primary"
              variant="contained"
              component="a"
              href="https://app.partrivia.com"
              size="large"
              endIcon={<ArrowForward />}
            >
              Create an account
            </Button>
            <Typography>
              Already a member?{' '}
              <Link fontWeight="lg" href="https://app.partrivia.com">
                Sign in
              </Link>
            </Typography>
          </Stack>
        </Grid>

        {/* RIGHT */}
        <Grid
          item
          xs={12}
          md={6}
          lg={7}
          sx={{ display: 'flex', width: '100%' }}
        >
          <Card
            variant="outlined"
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              pointerEvents: 'none',
              border: '2px solid #000',
              boxShadow: '4px 4px 5px 2px #2b1d1c',
            }}
          >
            <Box>
              <video
                src={`${process.env.PUBLIC_URL}/par-landing-vid.mp4`}
                autoPlay
                loop
                muted
                style={{ width: '100%', height: 'auto' }}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};
