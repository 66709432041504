import { IconButton } from '@mui/material';

type SocialMediaButtonPropTypes = {
  children: React.ReactNode;
  color?: string;
  href: string;
};
export const SocialMediaButton = ({
  children,
  color,
  href,
}: SocialMediaButtonPropTypes) => {
  return (
    <IconButton href={href} target="_blank" sx={{ alignSelf: 'center' }}>
      {children}
    </IconButton>
  );
};
