import { Box, CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { AppBar } from './app-bar';
import { BlockMain } from './blocks/block-main';
import './App.css';
import { BlockSchedule } from './blocks/block-schedule';
import { BlockArena } from './blocks/block-arena';
import { Block2 } from './blocks/block-2';

function App() {
  const defaultTheme = createTheme({
    palette: { mode: 'dark', background: { default: 'black' } },
  });

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Box
        sx={{
          backgroundColor: 'black',
          backgroundImage:
            'linear-gradient(to bottom, rgb(255 255 255 / 0%), rgb(255 255 255 / 0%), rgb(0 0 0), rgb(0 0 0)), url(/14.png)',
          // backgroundImage: 'url(/14.png)',
          // backgroundImage:
          // 'linear-gradient(to bottom, #00000000, rgba(0, 0, 0, 1)), url(/14.png)',
          backgroundPosition: { xs: 'top', sm: 'top', md: 'center' },
          backgroundSize: { xs: '150%', sm: '125%', md: 'cover' },
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'scroll',
          height: { xs: '100%', md: '100vh' },
          scrollSnapType: 'y mandatory',
          '& > div': {
            scrollSnapAlign: 'start',
          },
        }}
      >
        <AppBar />
        <BlockMain />
        <Block2 />
        <BlockArena />
        {/* <BlockSchedule /> */}
      </Box>
    </ThemeProvider>
  );
}

export default App;
