import { Box, Container, Stack, Typography } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GamepadIcon from '@mui/icons-material/Gamepad';

const features = [
  {
    icon: <AutorenewIcon style={{ marginRight: 10 }} />,
    title: 'Seasons',
    description: `Seasons will run for two months. At the end of each season, your highest Tier reached will be cemented in your Profile.`,
  },
  {
    icon: <AccessTimeIcon style={{ marginRight: 10 }} />,
    title: 'Schedule',
    description: `Two Arena Nights are held each week at various times. You don't have to make it to every single one. Join the days that are most convenient for you.`,
  },
  {
    icon: <GamepadIcon style={{ marginRight: 10 }} />,
    title: 'Format',
    description:
      'Each Arena Night will host six Arena Sets of 10 questions each. Every question is brand new. The game clock will go to 24 seconds. Expect 45 minutes to complete all six sets.',
  },
];

export const Block2 = () => {
  return (
    <Container id="block-2" sx={{ py: { xs: 8, sm: 8 } }}>
      <Typography
        color="primary"
        variant="body1"
        letterSpacing={2}
        textAlign={'center'}
      >
        Experience Multiplayer Trivia Like Never Before
      </Typography>
      <Typography
        variant="h4"
        // fontWeight={800}
        textAlign={'center'}
        mb={4}
        textTransform={'uppercase'}
      >
        Join the Ultimate Trivia League
      </Typography>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={4}
        py={6}
        px={4}
        sx={{
          bgcolor: '#172029d1',
        }}
      >
        {features.map((feature, index) => (
          <Stack key={index} spacing={2}>
            <Box display="flex" alignItems="center">
              {feature.icon}
              <Typography variant="h6" fontWeight={600} letterSpacing={2}>
                {feature.title}
              </Typography>
            </Box>

            <Typography variant="body2">{feature.description}</Typography>
          </Stack>
        ))}
      </Stack>
    </Container>
  );
};
