import { FaDiscord } from 'react-icons/fa6';
import TwitterIcon from '@mui/icons-material/X';
import { Box, Stack } from '@mui/material';
import { SocialMediaButton } from '../components/icon-buttons';

// function ColorSchemeToggle() {
//   const { mode, setMode } = useColorScheme();
//   const [mounted, setMounted] = useState(false);
//   useEffect(() => {
//     setMode('dark');
//     setMounted(true);
//   }, []);
//   if (!mounted) {
//     return null;
//   }
//   return (
//     <IconButton
//       id="toggle-mode"
//       size="large"
//       color="secondary"
//       onClick={() => {
//         if (mode === 'light') {
//           setMode('light');
//         } else {
//           setMode('dark');
//         }
//       }}
//       sx={{
//         borderRadius: '50%',
//         boxShadow: 'sm',
//       }}
//     >
//       {mode === 'light' ? <DarkModeRoundedIcon /> : <LightModeRoundedIcon />}
//     </IconButton>
//   );
// }

export const AppBar = () => {
  return (
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
      paddingX={3}
      bgcolor={'#0000009c'}
      position={'fixed'}
      top={0}
      width={'100%'}
      zIndex={999}
    >
      <Box
        sx={{
          zIndex: 999,
          height: '56px',
        }}
      >
        <img alt="PAR" src="/pink_logo.png" className="par-logo-dark"></img>
      </Box>

      <Stack direction={'row'} alignItems={'center'} spacing={2}>
        {/* <Link href="http://localhost:3000/pricing">Demo</Link> */}
        {/* <Link href="http://localhost:3000/pricing">Pricing</Link> */}
        <SocialMediaButton href={'https://twitter.com/ParTrivia'}>
          <TwitterIcon />
        </SocialMediaButton>
        <SocialMediaButton href={'https://discord.gg/upX48KPfHd'}>
          <FaDiscord />
        </SocialMediaButton>
      </Stack>
    </Stack>
  );
};
